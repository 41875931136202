import playIcon from "../../assets/play-icon.svg";

import styles from "./videoPlayer.module.css"

interface PlayIconProps {
  color?: string;
  className?: string;
  onClick?: () => void;
}

function PlayIcon({ color, className, onClick }: PlayIconProps) {
  return (
    <button onClick={onClick} className={styles.playIconButton}>
      <img src={playIcon.src} className={className} />
    </button>
  );
}

export default PlayIcon;
