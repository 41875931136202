import React, { type ReactNode } from "react";
import PlayIcon from "./PlayIcon";

import styles from "./videoPlayer.module.css";

interface VideoPlayerProps extends React.PropsWithChildren {
  alt?: string;
  src: string;
  type: string;
  iconColor: string;
  poster?: React.PropsWithChildren["children"];
}

function VideoPlayer({ alt, src, type, iconColor, poster }: VideoPlayerProps) {
  const [isPlaying, setIsPlaying] = React.useState(false);

  const videoRef = React.useRef<HTMLVideoElement>(null);

  React.useEffect(() => {
    if (!videoRef.current) return;
    // Add an observer to the video element to detect if it's not in view anymore
    // and pause it if it's not
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (!entry.isIntersecting) {
          videoRef.current!.pause();
          setIsPlaying(false);
        }
      },
      {
        root: null,
        threshold: 0.5,
      },
    );

    observer.observe(videoRef.current);

    return () => {
      observer.disconnect();
    };
  }, [videoRef, isPlaying]);

  const player = (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <video controls autoPlay className={styles.image} ref={videoRef}>
      <source src={src} type={type} />
      Your browser does not support the video tag.
    </video>
  );

  const posterComponent = (
    <div className={styles.posterContainer}>
      {poster as any}
      <PlayIcon
        className={styles.playIcon}
        onClick={() => setIsPlaying(true)}
        color={iconColor}
      />
    </div>
  );

  return (
    <div className={`${styles.VideoPlayer} ${styles.image}`}>
      {poster ? (isPlaying ? player : posterComponent) : player}
    </div>
  );
}

export default VideoPlayer;
